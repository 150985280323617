<div id="oauth_complete">
  <div class="title">Riot 인증이 완료되었습니다.</div>
  <div class="description">이제 창을 닫고 애플리케이션으로 돌아갈 수 있습니다.</div>
</div>

<style lang="scss">
  #oauth_complete {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
      margin-top: 200px;
    }

    .description {
      font-size: 1rem;
      opacity: 0.7;
    }
  }
</style>
